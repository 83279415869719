import './bootstrap';

import { createApp } from 'vue';
/**
 * Next, we will create a fresh Vue application instance. You may then begin
 * registering components with the application instance so they are ready
 * to use in your application's views. An example is included for you.
 */
let time, hiMessage;
const app = createApp({
	data() {
		return {
			userId: $('#user-id').val(),
			messages: [],
			typing: false,
			typingInterval: null,
			getScrollContainer: null,
			isChatOpen: false,
			hi_message: '',
		}
	},
	created() {
		this.hi_message = hiMessage;
		let that = this;
		$(document).ready(function () {
			that.getScrollContainer = document.querySelector('.ps-container');
			that.getScrollContainer.addEventListener('scroll', () => {
				let currScroll = that.currScroll();
				if (currScroll >= 89) {
					$('.scroll-bottom').removeClass('show');
				} else {
					$('.scroll-bottom').addClass('show');
				}
			});
			$(document).on('click', '.scroll-bottom', function () {
				that.chatScrollBottom(false, 100);
			});
			$(document).on('click', '.show-chat', function () {
				that.showChat();
			});
			setTimeout(function () {
				if (!that.isChatOpen) {
					that.showChat();
				}
			}, time);
		})
		this.updateSession();
		this.fetchMessages();

	},
	methods: {
		showChat() {
			$('.chat-button').toggleClass('hide')
			$('#chat-container').toggleClass('show');
			$('.ps-container').scroll();
			$('.ps-container').animate({ scrollTop: $('.ps-container')[0].scrollHeight }, 50);
			this.isChatOpen = !this.isChatOpen;
		},
		currScroll() {
			let height = $(".ps-container").height();
			let mainContentHeight = this.getScrollContainer.scrollTop;
			let scrollHeight = $(".ps-container")[0].scrollHeight - height;
			let currScroll = mainContentHeight / scrollHeight * 100;
			return currScroll;
		},
		chatScrollBottom(firstScroll = false, currScroll) {
			let anim = 1000;
			if (firstScroll) anim = 50;
			if (firstScroll || currScroll >= 95) {
				$('.ps-container').scroll();
				$('.ps-container').animate({ scrollTop: $('.ps-container')[0].scrollHeight }, anim);
			}
		},
		fetchMessages() {
			axios.post(apiUrl + '/api/getmessages', { isAdmin: false, user_id: this.userId }).then(response => {
				this.messages = response.data.messages;
				window.Echo.private(`chat.${response.data.userId}`)
					.listen('MessageSent', (e) => {
						this.messages[e.date].push(e.message);
						let currScroll = that.currScroll();
						this.chatScrollBottom(false, currScroll);
						if (!that.isChatOpen) {
							that.showChat();
						}
					}).listen('IsTyping', (e) => {
						this.typing = e.typing
						if (this.typing) {
							let dots = '';
							this.typingInterval = setInterval(function () {
								$('#istyping').html('Специалист печатает' + dots);
								if (dots != '...') {
									dots += '.';
								} else {
									dots = '';
								}
							}, 400);
						} else {
							clearInterval(this.typingInterval);
						}
					})

				let that = this;
				setTimeout(function () {
					that.chatScrollBottom(true);
				}, 1);
			});
		},
		updateSession() {
			axios.post('/api/chat/updatesessions');
		},
	}
});


import ExampleComponent from './components/ExampleComponent.vue';
import ChatMessages from './components/ChatMessages.vue';
import ChatForm from './components/ChatForm.vue';
import ChatButtonDesktop from './components/ChatButtonDesktop.vue';
import ChatButtonMobile from './components/ChatButtonMobile.vue';
import ChatContainer from './components/ChatContainer.vue';
//import ExampleComponent from './components/ExampleComponent.vue';
app.component('example-component', ExampleComponent);
app.component('chat-messages', ChatMessages);
app.component('chat-form', ChatForm);
app.component('chat-button-desktop', ChatButtonDesktop);
app.component('chat-button-mobile', ChatButtonMobile);
app.component('chat-container', ChatContainer);
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

//Object.entries(import.meta.glob('./**/*.vue', { eager: true })).forEach(([path, definition]) => {
//app.component(path.split('/').pop().replace(/\.\w+$/, ''), definition.default);
//});

/**
 * Finally, we will attach the application instance to a HTML element with
 * an "id" attribute of "app". This element is included with the "auth"
 * scaffolding. Otherwise, you will need to add an element yourself.
 */

app.setMount = () => {
	axios.post(apiUrl + '/api/chat/settings').then(res => {
		time = res.data.time_to_show != null ? res.data.time_to_show : 5000;
		hiMessage = res.data.hi_message;
		app.mount('#chat');
	});
}

window.App = app;